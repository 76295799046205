import React from 'react';
import styled from 'styled-components';
import {Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../../AppActions';
import {
  getUserStateLabel,
  getUserSubstateLabel,
  getUserSexLabel,
} from '../../../Utils/UserUtil';
import {getMeetingTypeLabel} from '../../../Utils/SelectOptionLabelUtil';
import {dtStrExp} from '../../../Utils/TimeUtil';
import AdminActivityLunchExport from './AdminActivityLunchExport';

function AdminDashboardExport(props) {
  //會員人數：性別
  const [uprList1, setUprList1] = React.useState(null);
  const [downloadUrl1, setDownloadUrl1] = React.useState(null);

  //會員人數：次狀態
  const [uprList2, setUprList2] = React.useState(null);
  const [downloadUrl2, setDownloadUrl2] = React.useState(null);

  //在職進修課程
  const [productList1, setProductList1] = React.useState(null);
  const [orderList1, setOrderList1] = React.useState(null);
  const [startDate1, setStartDate1] = React.useState('');
  const [endDate1, setEndDate1] = React.useState('');
  const [downloadUrl3, setDownloadUrl3] = React.useState(null);

  //社團活動
  const [productList3, setProductList3] = React.useState(null);
  const [orderList3, setOrderList3] = React.useState(null);
  const [startDate3, setStartDate3] = React.useState('');
  const [endDate3, setEndDate3] = React.useState('');
  const [downloadUrl5, setDownloadUrl5] = React.useState(null);

  //外賓來訪、會內活動
  const [meetingList1, setMeetingList1] = React.useState(null);
  const [intExcList1, setIntExcList1] = React.useState(null);
  const [productList4, setProductList4] = React.useState(null);
  const [startDate4, setStartDate4] = React.useState('');
  const [endDate4, setEndDate4] = React.useState('');
  const [downloadUrl6, setDownloadUrl6] = React.useState(null);
  const [downloadUrl7, setDownloadUrl7] = React.useState(null);
  const [downloadUrl8, setDownloadUrl8] = React.useState(null);

  //派員參與會議
  const [meetingList2, setMeetingList2] = React.useState(null);
  const [startDate5, setStartDate5] = React.useState('');
  const [endDate5, setEndDate5] = React.useState('');
  const [downloadUrl9, setDownloadUrl9] = React.useState(null);

  return (
    <Wrapper>
      <ItemWrapper>
        <div className="title">會員人數：性別</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div>性別：男性、女性</div>
        <div className="container">
          {!uprList1 && (
            <div className="content">
              <div>總人數：--- 人</div>
              <div>男性： --- 人</div>
              <div>女性： --- 人</div>
              <div>未填寫： --- 人</div>
            </div>
          )}
          {uprList1 && (
            <div className="content">
              <div>總人數：{uprList1.total} 人</div>
              <div>
                男性：
                {uprList1.results.filter((r) => r.SEX === 'M').length} 人
              </div>
              <div>
                女性：
                {uprList1.results.filter((r) => r.SEX === 'F').length} 人
              </div>
            </div>
          )}
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                const resp = await JStorage.fetchDocuments(
                  'user_profile',
                  {
                    SEX: {$exists: true},
                    state: 1,
                    substate: {
                      $in: ['4', '5', '6'],
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {
                    owner: 1,
                    name: 1,
                    SEX: 1,
                    id_card_number: 1,
                    state: 1,
                    substate: 1,
                  },
                );

                setUprList1(resp);

                const link = await generateExcelFile(
                  resp.results,
                  ['身分證字號', '姓名', '狀態', '次狀態', '性別'],
                  (r) => [
                    r.id_card_number,
                    r.name,
                    getUserStateLabel(r.state),
                    getUserSubstateLabel(r.substate),
                    getUserSexLabel(r.SEX),
                  ],
                );

                if (link) {
                  setDownloadUrl1(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl1 && (
              <Button
                type="link"
                download={'會員人數_性別.xlsx'}
                href={downloadUrl1}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">會員人數：次狀態</div>
        <div>狀態：入會</div>
        <div>次狀態：一般會員、特別會員、外國法事務律師</div>
        <div className="container">
          {!uprList2 && (
            <div className="content">
              <div>總人數：--- 人</div>
              <div>一般會員： --- 人</div>
              <div>特別會員： --- 人</div>
              <div>外國法事務律師： --- 人</div>
            </div>
          )}
          {uprList2 && (
            <div className="content">
              <div>總人數：{uprList2.total} 人</div>
              <div>
                一般會員：
                {uprList2.results.filter((r) => r.substate === '4').length}人
              </div>
              <div>
                特別會員：
                {uprList2.results.filter((r) => r.substate === '5').length}人
              </div>
              <div>
                外國法事務律師：
                {uprList2.results.filter((r) => r.substate === '6').length}人
              </div>
            </div>
          )}
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                const resp = await JStorage.fetchDocuments(
                  'user_profile',
                  {
                    state: 1,
                    substate: {
                      $in: ['4', '5', '6'],
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {owner: 1, id_card_number: 1, name: 1, state: 1, substate: 1},
                );

                setUprList2(resp);

                const link = await generateExcelFile(
                  resp.results,
                  ['身分證字號', '姓名', '狀態', '次狀態'],
                  (r) => [
                    r.id_card_number,
                    r.name,
                    getUserStateLabel(r.state),
                    getUserSubstateLabel(r.substate),
                  ],
                );

                if (link) {
                  setDownloadUrl2(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl2 && (
              <Button
                type="link"
                download={'會員人數_次狀態.xlsx'}
                href={downloadUrl2}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">在職進修課程</div>
        <div>分類：課程、實體課程、專錄課程、進修課程、影音課程</div>
        <div className="container">
          <div className="content">
            開始日期：
            <input
              type="date"
              value={startDate1}
              onChange={(e) => setStartDate1(e.target.value)}
            />
          </div>
          <div className="content">
            結束日期：
            <input
              type="date"
              value={endDate1}
              onChange={(e) => setEndDate1(e.target.value)}
            />
          </div>

          <div className="content">
            {!productList1 && (
              <div>
                <div>總場次：--- 場</div>
                <div>報名人數最多：--- 人</div>
                <div>領域：--- 場</div>
              </div>
            )}

            {productList1 && (
              <div>
                <div>總場次：{productList1.total} 場</div>
                <div>報名人數最多：{getOrderList(orderList1)}</div>
                <div>領域：{getExpertiseList(productList1)}</div>
              </div>
            )}
          </div>

          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                if (!dtStrExp.test(startDate1)) {
                  message.warning('開始日期格式錯誤');
                  return;
                }

                if (!dtStrExp.test(endDate1)) {
                  message.warning('結束日期格式錯誤');
                  return;
                }

                const products = await JStorage.fetchDocuments(
                  'product',
                  {
                    session: {$exists: true},
                    'session.date': {
                      $gte: startDate1,
                      $lt: endDate1,
                    },
                    labels: {$regex: '課程'},
                    archived: {$ne: true},
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {
                    id: 1,
                    name: 1,
                    expertises: 1,
                    session: 1,
                    labels: 1,
                    serial_number: 1,
                  },
                );

                setProductList1(products);

                const orders = await JStorage.fetchDocuments(
                  'order',
                  {
                    'items.0.product.id': {
                      $in: products.results
                        .map((p) => p.id)
                        .map((id) => ({$oid: id})),
                    },
                    payment_status: 'success',
                    status: {
                      $nin: [
                        'canceled',
                        'return_applied',
                        'return_completed',
                        'time_canceled',
                      ],
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {items: 1},
                );

                setOrderList1(orders);

                const link = await generateExcelFile(
                  products.results,
                  ['編號', '名稱', '場次日期', '分類', '領域', '報名人數'],
                  (r) => {
                    const _os = (orders.results || []).filter((c) => {
                      return c.items[0].product.id.$oid === r.id;
                    });

                    return [
                      r.serial_number,
                      r.name,
                      `${r.session.date}`,
                      generateArrayOfStringText(r.labels),
                      generateArrayOfStringText(r.expertises),
                      _os.length,
                    ];
                  },
                );

                if (link) {
                  setDownloadUrl3(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl3 && (
              <Button
                type="link"
                download={'在職進修課程.xlsx'}
                href={downloadUrl3}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <AdminActivityLunchExport generateExcelFile={generateExcelFile} />

      <ItemWrapper>
        <div className="title">社團活動</div>
        <div>公會內部分類：次團活動</div>
        <div className="container">
          <div className="content">
            開始日期：
            <input
              type="date"
              value={startDate3}
              onChange={(e) => setStartDate3(e.target.value)}
            />
          </div>
          <div className="content">
            結束日期：
            <input
              type="date"
              value={endDate3}
              onChange={(e) => setEndDate3(e.target.value)}
            />
          </div>

          <div className="content">
            {!productList3 && (
              <div>
                <div>總場次：--- 場</div>
                <div>報名人數最多：--- 人</div>
              </div>
            )}

            {productList3 && (
              <div>
                <div>總場次：{productList3.total} 場</div>
                <div>報名人數最多：{getOrderList(orderList3)}</div>
              </div>
            )}
          </div>
          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                if (!dtStrExp.test(startDate3)) {
                  message.warning('開始日期格式錯誤');
                  return;
                }

                if (!dtStrExp.test(endDate3)) {
                  message.warning('結束日期格式錯誤');
                  return;
                }

                const products = await JStorage.fetchDocuments(
                  'product',
                  {
                    internal_label: {$regex: '次團活動'},
                    session: {$exists: true},
                    'session.date': {
                      $gte: startDate3,
                      $lt: endDate3,
                    },
                    archived: {$ne: true},
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {name: 1, internal_label: 1, labels: 1, session: 1},
                );

                setProductList3(products);

                const orders = await JStorage.fetchDocuments(
                  'order',
                  {
                    'items.0.product.id': {
                      $in: products.results
                        .map((p) => p.id)
                        .map((id) => ({$oid: id})),
                    },
                    payment_status: 'success',
                    status: {
                      $nin: [
                        'canceled',
                        'return_applied',
                        'return_completed',
                        'time_canceled',
                      ],
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {items: 1},
                );

                setOrderList3(orders);

                const link = await generateExcelFile(
                  products.results,
                  ['名稱', '場次日期', '分類', '公會內部分類', '報名人數'],
                  (r) => {
                    const _os = (orders.results || []).filter((c) => {
                      return c.items[0].product.id.$oid === r.id;
                    });

                    return [
                      r.name,
                      `${r.session.date}`,
                      generateArrayOfStringText(r.labels),
                      r.internal_label,
                      _os.length,
                    ];
                  },
                );

                if (link) {
                  setDownloadUrl5(link);
                }
              }}>
              查詢
            </Button>
            {downloadUrl5 && (
              <Button
                type="link"
                download={'社團活動.xlsx'}
                href={downloadUrl5}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">外賓來訪、會內活動</div>
        <div>會議名稱關鍵字：來訪、儀式</div>
        <div>國際交流名稱關鍵字：來訪、儀式</div>
        <div>課程/活動公會內部分類：接待活動、拜會活動</div>
        <div className="container">
          <div className="content">
            開始日期：
            <input
              type="date"
              value={startDate4}
              onChange={(e) => setStartDate4(e.target.value)}
            />
          </div>
          <div className="content">
            結束日期：
            <input
              type="date"
              value={endDate4}
              onChange={(e) => setEndDate4(e.target.value)}
            />
          </div>

          <div className="content">
            {!meetingList1 && !intExcList1 && (
              <div>
                <div>會議總場次：--- 場</div>
                <div>國際交流總場次：--- 場</div>
                <div>課程/活動總場次：--- 場</div>
              </div>
            )}

            {(meetingList1 || intExcList1 || productList4) && (
              <div>
                <div>會議總場次：{meetingList1?.total} 場</div>
                <div>國際交流總場次：{intExcList1?.total} 場</div>
                <div>課程/活動總場次：{productList4?.total} 場</div>
              </div>
            )}
          </div>

          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                if (!dtStrExp.test(startDate4)) {
                  message.warning('開始日期格式錯誤');
                  return;
                }

                if (!dtStrExp.test(endDate4)) {
                  message.warning('結束日期格式錯誤');
                  return;
                }

                const meetings = await JStorage.fetchDocuments(
                  'Meeting',
                  {
                    $or: [
                      {MTNG_NAME: {$regex: '來訪'}},
                      {MTNG_NAME: {$regex: '儀式'}},
                    ],
                    EFF_DATE: {
                      $gte: startDate4,
                      $lt: endDate4,
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {MTNG_NAME: 1, MTNG_TYPE: 1, EFF_DATE: 1, START_TIME: 1},
                );

                setMeetingList1(meetings);

                const meetingsLink = await generateExcelFile(
                  meetings.results,
                  ['名稱', '類別', '會議日期', '開始時間'],
                  (r) => {
                    return [
                      r.MTNG_NAME,
                      getMeetingTypeLabel(r.MTNG_TYPE),
                      r.EFF_DATE,
                      r.START_TIME,
                    ];
                  },
                );

                if (meetingsLink) {
                  setDownloadUrl6(meetingsLink);
                }

                const intExcs = await JStorage.fetchDocuments(
                  'international_exchange',
                  {
                    $or: [{title: {$regex: '來訪'}}, {title: {$regex: '儀式'}}],
                    exchange_start_date: {
                      $gte: startDate4,
                      $lt: endDate4,
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {
                    title: 1,
                    country: 1,
                    exchange_unit: 1,
                    exchange_start_date: 1,
                  },
                );

                setIntExcList1(intExcs);

                const intExcListLink = await generateExcelFile(
                  intExcs.results,
                  ['名稱', '國家/地區', '交流單位', '交流開始日期'],
                  (r) => {
                    return [
                      r.title,
                      r.country,
                      r.exchange_unit,
                      r.exchange_start_date,
                    ];
                  },
                );

                if (intExcListLink) {
                  setDownloadUrl7(intExcListLink);
                }

                const products = await JStorage.fetchDocuments(
                  'product',
                  {
                    $or: [{internal_label: {$in: ['接待活動', '拜會活動']}}],
                    session: {$exists: true},
                    'session.date': {
                      $gte: startDate4,
                      $lt: endDate4,
                    },
                    archived: {$ne: true},
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {name: 1, internal_label: 1, labels: 1, session: 1},
                );

                setProductList4(products);

                const productLink = await generateExcelFile(
                  products.results,
                  ['名稱', '場次日期', '分類', '公會內部分類'],
                  (r) => {
                    return [
                      r.name,
                      `${r.session.date}`,
                      generateArrayOfStringText(r.labels),
                      r.internal_label,
                    ];
                  },
                );

                if (productLink) {
                  setDownloadUrl8(productLink);
                }
              }}>
              查詢
            </Button>
            <div style={{marginTop: 10}}>
              {downloadUrl6 && (
                <Button
                  size="small"
                  type="link"
                  download={'外賓來訪及會內活動_會議.xlsx'}
                  href={downloadUrl6}
                  target="_blank">
                  下載會議報表
                </Button>
              )}
              {downloadUrl7 && (
                <Button
                  size="small"
                  type="link"
                  download={'外賓來訪及會內活動_國際交流.xlsx'}
                  href={downloadUrl7}
                  target="_blank">
                  下載國際交流報表
                </Button>
              )}
              {downloadUrl8 && (
                <Button
                  size="small"
                  type="link"
                  download={'外賓來訪及會內活動_課程活動.xlsx'}
                  href={downloadUrl8}
                  target="_blank">
                  下載課程活動報表
                </Button>
              )}
            </div>
          </div>
        </div>
      </ItemWrapper>

      <ItemWrapper>
        <div className="title">派員參與會議</div>
        <div>會議類型：外部、會員大會、理事會、監事會</div>
        <div className="container">
          <div className="content">
            開始日期：
            <input
              type="date"
              value={startDate5}
              onChange={(e) => setStartDate5(e.target.value)}
            />
          </div>
          <div className="content">
            結束日期：
            <input
              type="date"
              value={endDate5}
              onChange={(e) => setEndDate5(e.target.value)}
            />
          </div>

          <div className="content">
            {!meetingList2 && (
              <div>
                <div>會議總場次：--- 場</div>
              </div>
            )}

            {meetingList2 && (
              <div>
                <div>會議總場次：{meetingList2?.total} 場</div>
              </div>
            )}
          </div>

          <div className="content">
            <Button
              type="primary"
              onClick={async () => {
                if (!dtStrExp.test(startDate5)) {
                  message.warning('開始日期格式錯誤');
                  return;
                }

                if (!dtStrExp.test(endDate5)) {
                  message.warning('結束日期格式錯誤');
                  return;
                }

                const meetings = await JStorage.fetchDocuments(
                  'Meeting',
                  {
                    MTNG_TYPE: {
                      $in: ['OUTER', 'GSM', 'BM', 'SV'],
                    },
                    EFF_DATE: {
                      $gte: startDate5,
                      $lt: endDate5,
                    },
                  },
                  ['-created'],
                  {offset: 0, limit: 10000000},
                  {MTNG_NAME: 1, MTNG_TYPE: 1, EFF_DATE: 1, CEXECUTE: 1},
                );

                setMeetingList2(meetings);

                const meetingsLink = await generateExcelFile(
                  meetings.results,
                  ['名稱', '類別', '會議日期', '開始時間', '執行情形'],
                  (r) => {
                    return [
                      r.MTNG_NAME,
                      getMeetingTypeLabel(r.MTNG_TYPE),
                      r.EFF_DATE,
                      r.START_TIME,
                      r.CEXECUTE,
                    ];
                  },
                );

                if (meetingsLink) {
                  setDownloadUrl9(meetingsLink);
                }
              }}>
              查詢
            </Button>
            {downloadUrl9 && (
              <Button
                type="link"
                download={'派員參與會議.xlsx'}
                href={downloadUrl9}
                target="_blank">
                下載報表
              </Button>
            )}
          </div>
        </div>
      </ItemWrapper>
    </Wrapper>
  );
}

function generateArrayOfStringText(array) {
  let text = '';

  if (Array.isArray(array) && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      text += `${array[i]}${i + 1 !== array.length ? '、' : ''}`;
    }
  }

  return text;
}

function getOrderList(resp) {
  let text = '';

  if (resp) {
    const {total, results} = resp;
    let checkoutList = [];

    for (let i = 0; i < results.length; i++) {
      const _cName = results[i].items[0].product.name;
      const _cId = results[i].items[0].product.id.$oid;
      const index = checkoutList.findIndex((c) => c.id === _cId);

      if (index === -1) {
        checkoutList.push({id: _cId, name: _cName, total: 1});
      } else {
        checkoutList[index].total = checkoutList[index].total + 1;
      }
    }

    if (checkoutList.length > 0) {
      const rankingList = checkoutList
        .sort((a, b) => b.total - a.total)
        .filter((_, i) => i < 3);

      for (let x = 0; x < rankingList.length; x++) {
        text += `${rankingList[x].name} ${rankingList[x].total} 人 ${
          x + 1 !== rankingList.length ? `、` : ``
        }`;
      }
    }
  }

  return text;
}

function getExpertiseList(resp) {
  let text = '';

  if (resp) {
    const {total, results} = resp;
    let expertisesList = [];

    for (let i = 0; i < results.length; i++) {
      const _p = results[i];
      const expertises = _p.expertises || [];

      if (Array.isArray(expertises) && expertises.length > 0) {
        for (let n = 0; n < expertises.length; n++) {
          const _e = expertises[n];

          const index = expertisesList.findIndex((e) => e.name === _e);

          if (index === -1) {
            expertisesList.push({name: _e, total: 1});
          } else {
            expertisesList[index].total = expertisesList[index].total + 1;
          }
        }
      }
    }

    if (expertisesList.length > 0) {
      for (let d = 0; d < expertisesList.length; d++) {
        text += `${expertisesList[d].name}
          ${expertisesList[d].total} 場 ${
          d + 1 !== expertisesList.length ? `、` : ``
        }`;
      }
    }
  }

  return text;
}

async function generateExcelFile(records, header, generateRow) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([
      header,
      ...records.map(generateRow),
    ]);
    window.XLSX.utils.book_append_sheet(wb, ws, '訂單列表');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    message.success('成功創建下載連結');
    return objUrl;
  } catch (ex) {
    console.warn('generateExcelFile ex', ex);
  } finally {
    AppActions.setLoading(false);
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .item-wrapper {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width: 400px;
    width: 100%;
    padding: 10px;
    margin: 20px;

    & > .title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    & > .container {
      margin-top: 10px;
      margin-bottom: 10px;

      & > .content {
        margin-top: 10px;
        margin-bottom: 10px;

        & > * {
          font-size: 14px;
        }
      }
    }
  }
`;

const ItemWrapper = styled.div`
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  margin: 20px;

  & > .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  & > .container {
    margin-top: 10px;
    margin-bottom: 10px;

    & > .content {
      margin-top: 10px;
      margin-bottom: 10px;

      & > * {
        font-size: 14px;
      }
    }
  }
`;

export default AdminDashboardExport;
